import React from 'react';

import { Link } from 'react-router-dom';

export default () => {
    return (
        <>
            <section className="page-blog__text">
                <div className="page-blog__text-container page-blog__text-container--columns">
                    <p className="page-blog__paragraph page-blog__intro">
                        Ismerős? Ha hallottad már a fenti mondatot, a fenti időpontban, jó helyen jársz. Miért is? Mivel ez a rövid cikk pont azoknak szól, akik
                        sokat adnak, de még többet kérnek tőlük.
                    </p>
                    <p className="page-blog__paragraph">Ez a kérés sokszor elhangozhat, de az alábbi esetek tipikusak:</p>
                    <ul>
                        <li>Jószívű vagy és szeretnéd előre vinni a csapatot, amiben dolgozol, elvállalod a kapott feladatot.</li>
                        <li>A kollégáid is bent ülnek még, nem akarod, hogy kinézzenek, ezért te rábólintasz, megcsinálod.</li>
                        <li>Félsz, hogy kirúgnak, ezért nem mondasz nemet, túlteljesítesz.</li>
                        <li>Egyszerűen nem szeretsz nemet mondani, ezért általában túlvállalod magad, ezzel a feladattal is.</li>
                    </ul>
                    <p className="page-blog__paragraph">
                        És persze utána jön a jutalom, ami nem fizetett túlóra, vagy a család és a barátok megbántása. Vagy a jobbik eset, kiszúrják a szemedet
                        valamekkora túlóra összeggel, teszem azt "100%-al", és ezzel te meg is elégszel. Kár, hogy mások isszák meg a levét, azok, akik esetleg
                        máshol is számítanak rád, például otthon.
                    </p>
                    <img className="page-blog__article-image" src="/images/blog/faradt-fiatal-alkalmazott.jpg" alt="Fáradt fiatal alkalmazott" />
                    <p className="page-blog__paragraph">
                        Nem egy egyszerű helyzet, tény. Kinek felelj meg? A főnöködnek vagy akik fontosak neked? Mi van, ha elvész a stabil megélhetésed ha nem
                        vállalod be? Kérdezted már magadtól: Nincs olyan megoldás, ahol a munkahelyed is elégedett veled, és más dolgokra is jut időd?
                    </p>
                    <p className="page-blog__paragraph">
                        Az őszinte válasz: Talán van. És ez a talán pontosan elég. Mi saját tapasztalatból tudunk tanácsot adni, ha hasonló cipőben jársz:
                        Gondolkodj valami olyan megoldáson, ahol a saját magad főnöke tudsz lenni. Magadnak talán meg tudsz felelni, és ha nagyobb a saját
                        életed felett az irányítás, olyan emberekkel tudod magad körül venni, akik nem fogják önző érdekből az idődet elvenni.{' '}
                    </p>
                    <img className="page-blog__article-image" src="/images/blog/sikeres-vallalkozo.jpg" alt="Sikeres vállalkozó" />
                    <p className="page-blog__paragraph">
                        Ez a cikk nem más, mint egy bátorítás, hogy merj saját vállalkozásba kezdeni. Miért? Mert ott is ugyanúgy dolgoznod kell, csak épp a jó
                        célért. Azért a célért, amit te tartasz jónak. Legyen ez elsőre bármennyire elrugaszkodott,{' '}
                        <strong>lehet, hogy pont a te ötleted lesz az amire mindenkinek szüksége van</strong>. Mindemellett, hosszú távon biztosan csak azt
                        fogod tudni csinálni, amiben igazán hiszel és tudod, hogy a jó ügyet szolgálja. Melyiket nehezebb leküzdeni, a félelmet, ami visszatart
                        az ötleted megvalósításától, vagy a megbánást, amit akkor érzel majd, ha elszalasztottad a lehetőségedet?
                    </p>
                    <p className="page-blog__paragraph">
                        A cikknek ezen következő része olyanoknak szól, akiknek már van egy cél a fejükben, mégpedig pont olyan amely megvalósításában mi is
                        segíthetünk. Ha már rájöttél, hogy nem akarsz több túlórát, és energiádat inkább egy erős online jelenlétre építkező vállalkozásba
                        fektetnéd, lehet pont mi vagyunk az embereid. A{' '}
                        <Link className="page-blog__link" to="/">
                            DigitalBuilds
                        </Link>{' '}
                        alapítói 3 túlóra ellenes programozó, akiknek a család és a barátok fontosabbak voltak, mint a főnök új kocsijához bevételt termelni.
                    </p>
                    <p className="page-blog__paragraph">
                        Mi hiszünk az egymásért való munkában, a minőség erejében és a transzparens döntésekben. Elítéljük a titkolózást és bizalomra építünk, a
                        hosszútávon fenntartható, eredményes együttműködést szem előtt tartva.
                    </p>
                </div>
            </section>
        </>
    );
};
