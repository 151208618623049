import React from 'react';

import { Link } from 'react-router-dom';

export default () => {
    return (
        <>
            <section className="page-blog__text">
                <div className="page-blog__text-container page-blog__text-container--columns">
                    <p className="page-blog__paragraph page-blog__intro">
                        Mindannyian törekszünk a biztonságra és a kiszámítható környezetre. Sok interview-n és álláshirdetésben hallani is, hogy kiszámítható,
                        biztos jövedelem, tervezhető karrierút, satöbbi, satöbbi. Nyilván nem véletlen, hiszen a cégek tudják nagyon jól, hogy számodra pont ez
                        kell.
                    </p>
                    <p className="page-blog__paragraph">
                        Amibe viszont lehet nem gondolsz bele, hogy nekik te sokkal jobban kellesz, mint ők neked. És itt biztos vannak olyan élethelyzetek,
                        ahol ez nem igaz, ahol erős a verseny, vagy épp rászorulsz egy alkalmazotti viszonyra, de ez a cikk most olyanoknak szól, akik kezdik
                        belátni, vagy még nem látják, hogy megvezeti őket a munkáltatójuk. Hogyan vezet meg? Nem igaz a biztos jövedelem? Nem igaz a
                        kiszámítható környezet? Sajnos de, és pont ez a baj. Ezzel a két dologgal eladják neked a biztonság illúzióját, ezért ezen két irány
                        mentén fogok haladni én is.
                    </p>
                    <h3>Első illúzió: a biztos jövedelem</h3>
                    <p className="page-blog__paragraph">
                        Tegyük fel, hogy a 2020-as magyar átlagot keresed. (Ami jó eset, mert valószínű az átlagot csak pár ember húzza fel, de az nagyon) Ez
                        255 ezer forint körüli, amivel napról napra eléldegélsz a fővárosban. Dehát, ha eléldegélek az jó, nem? Csak életem végéig ezt kell
                        tennem! A bajok ott kezdődnek, hogy a kereseted állandó, de az élet kiszámíthatatlan. Gondoljunk csak ilyenekbe bele: betegség, baleset,
                        anyagi károk. Ilyenkor már is jön egy kölcsön. (Ezt még később előhozom) Aztán ugye ott van, hogy nyugdíj. Mikor legutóbb rendelőben
                        jártál, mennyi volt az átlag életkor? Ne legyenek illúzióid, rád is ez vár (és ez a jó eset). X év felett már nem munkába jársz, hanem
                        rendelőbe, hol lesz akkor a stabil jövedelmed, amiért cserébe eddig az idődet adtad? Megoldás, amit mindenki hirdet: Nyugdíjpénztár!!
                        Tegyél félre!! De biztosan ennyi elég? Ez a tuti megoldás? Kiszámoltad valaha mennyit kellene félretenned? Most 3 sorban megtudhatod:{' '}
                    </p>
                    <ol>
                        <li>Nyugdíj után ennyit élsz: 20 év</li>
                        <li>Életszínvonal vesztés nélkül ennyit elköltesz havonta: 255 nettó</li>
                        <li>
                            Életed végéig ez összesen: 20 * 12 * 255 * infláció = <strong>155 931 480 Ft</strong>
                        </li>
                    </ol>
                    <p className="page-blog__paragraph">
                        Ergo, 65 éves korodra ennyit kell félretenned (az elmúlt 20 év inflációja alapján). Szóval ezt a célt adta el neked a nyugdíjpénztár,
                        vagy ahol éppen gyűjtögetsz, és te megvetted. (Ezt így látva, jó vétel volt?)
                    </p>
                    <h3>Második illúzió: a kiszámítható környezet</h3>
                    <p className="page-blog__paragraph">
                        Itt is hasonló problémák vannak, mint az elsővel. A munkád állandó, az élet változik körülötted. Ezzel az baj, hogy nem fogsz tudni az
                        élettel változni, ha esetleg rászorulsz. Miért? Nézzük meg:
                    </p>
                    <ul>
                        <li>Kellene a fizetésemelés, mert nőnek a kiadásaid, vagy csak érezni akarod, hogy előre lépsz.</li>
                    </ul>
                    <p className="page-blog__paragraph">
                        Miért nem fogsz kapni? Mert a cégednél bérsávok vannak és inkább hagynak elmenni. Mert mikor felvettek, már számoltak azzal, hogy
                        felmondhatsz. A cég egy üzlet, te vagy a termék. Ha megéred nekik megvesznek, ha nem, nem. Minden más csak illúziókeltés. (Erről szól a
                        kapitalizmus)
                    </p>
                    <ul>
                        <li>Dehát 10 éve itt dolgozok, nem érdemlem meg?</li>
                    </ul>
                    <p className="page-blog__paragraph">
                        De, megérdemled, csak nem fogsz kapni, mert nem éri meg adni, akármennyire könyörtelenül is hangzik. Miért? Mert nézz körül, ledolgoztál
                        10 évet, minden intrikáját tudod a cégednek, de mi mást tudsz? Hova tudnál máshova menni? RÁSZORULSZ a cégre, ahol dolgozol! És itt
                        kezdődik a kizsákmányolás, ami az idővel egyre mélyül. Persze eddigre már baleset vagy lakás miatt hitelt is vettél fel, szóval amiatt
                        is maradnod kell. Aztán családot is alapítottál, akik számítanak arra, hogy minden hónapban ugyanannyit vigyél haza.
                    </p>
                    <p className="page-blog__paragraph">
                        Összegezve, a várható jövőkép a számodra: amíg csak tudsz járni, ennél a cégnél fogsz dolgozni, szélmalomharcot vívsz majd a főnökkel az
                        emelésért, miközben az életed 1/3-a, a napi 8 óra, elmegy arra, hogy másoknak termeltél óriási hasznot. De várj, majd elfelejtettem, 155
                        milliót azért félretettél ugye? És persze, jó dolog másokon segíteni, de biztos, hogy a jó ügyért tetted? Ha visszanézel majd ezekre az
                        évekre, örülsz majd, hogy a főnököd megvehette miattad az új lakását?
                    </p>
                    <p className="page-blog__paragraph">
                        Sajnos mindez nem konspiráció, hanem piaci trend. Aki adaptálódik a kapitalista berendezkedéshez, ezeket a módszereket fogja alkalmazni,
                        hogy a cégéhez csábítson, és így fog veled eljárni. Mert látja, hogy működik és látja, hogy megéri.
                    </p>
                    <p className="page-blog__paragraph">
                        Ha ezt kellően aggasztónak találod, azért megnyugtató, hogy van kiút, amit sokan megtaláltak már. Ez nem más, mint a saját vállalkozás
                        alapítása. A cikkben leírtak fényében a legjobb tanács, amit adhatunk, hogy kezdj el gondolkodni valamilyen ötleten, azaz légy a magad
                        ura. Így olyan emberekkel veheted majd körül magad, akikkel EGYÜTT dolgozol, nem pedig kizsákmányolnak. Egy jó és közös célon osztozva
                        tudtok majd előre haladni, illúziók nélkül. Ez tudja garantálni számodra a biztonságot és a felkészültséget az élet valamennyi
                        kiszámíthatatlanságára: szabad időbeosztás, átlátható döntések és skálázható bevétel.
                    </p>
                    <p className="page-blog__paragraph">
                        Mi a{' '}
                        <Link className="page-blog__link" to="/">
                            DigitalBuilds
                        </Link>
                        -nél azt tűztük ki célnak, hogy hasonló gondolkodású embereknek segítünk megvalósítani ötleteiket, vállalkozói terveiket, mint a magunk
                        urai. Programozóként elsősorban online vállalkozásokban gondolkodókkal dolgozunk együtt. Ha véletlenül már van egy ilyen elképzelésed és
                        ez a cikk meggyőzött, hogy érdemes ténylegesen komolyan foglalkoznod vele, mi segíthetünk átlendülni az akadályokon. Ha hasonló cipőben
                        jársz, mint mi is jártunk, szívesen fogunk veled dolgozni.
                    </p>
                </div>
            </section>
        </>
    );
};
