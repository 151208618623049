import React, { useContext, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Lang from '../Language/Lang';
import LanguageContext from '../Language/LanguageContext';
import Hamburger from '../Hamburger/Hamburger';

export default ({ children, backgroundImageUrl }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const langContext = useContext(LanguageContext);
    const changeLanguage = event => {
        event.preventDefault();
        langContext.changeLanguage(langContext.currentLanguage === 'en' ? 'hu' : 'en');
        togglMenu(false);
    };
    const body = document.getElementsByTagName('body')[0];

    const menuClasses = menuOpen ? 'page-header__nav page-header__nav-open' : 'page-header__nav';

    const togglMenu = open => {
        body.style.position = open ? 'fixed' : 'static';
        setMenuOpen(open);
    };

    return (
        <div style={{ backgroundImage: `url(${backgroundImageUrl})` }} className="page-header-wrapper">
            <header className="page-header">
                <Link to="/" className={menuOpen ? 'logo__menu-open' : ''} onClick={() => togglMenu(false)}>
                    <img src="/images/digitalbuilds-logo.svg" alt="DigitalBuilds logo" title="DigitalBuilds – Ahol az ötleted valóra válik" />
                </Link>
                <nav className={menuClasses}>
                    <ul className="nav-list">
                        <li className="nav-list__item">
                            <Link className="nav-list__link" to="/" onClick={() => togglMenu(false)}>
                                <Lang>Főoldal</Lang>
                                <Lang lang="en">Home</Lang>
                            </Link>
                        </li>
                        <li className="nav-list__item">
                            <Link
                                className="nav-list__link"
                                to={{
                                    pathname: '/',
                                    hash: '#miert-valassz-minket',
                                }}
                                onClick={() => togglMenu(false)}
                            >
                                <Lang>Miért válassz minket?</Lang>
                                <Lang lang="en">Why us?</Lang>
                            </Link>
                        </li>
                        <li className="nav-list__item">
                            <Link
                                className="nav-list__link"
                                to={{
                                    pathname: '/',
                                    hash: '#munkaink',
                                }}
                                onClick={() => togglMenu(false)}
                            >
                                <Lang>Munkáink</Lang>
                                <Lang lang="en">Selection from previous works</Lang>
                            </Link>
                        </li>
                        <Lang>
                            <li className="nav-list__item">
                                <Link className="nav-list__link" to="/blog" onClick={() => togglMenu(false)}>
                                    Blog
                                </Link>
                            </li>
                        </Lang>
                        <li className="nav-list__item">
                            <Link
                                className="nav-list__link"
                                to={{
                                    pathname: '/',
                                    hash: '#kapcsolat',
                                }}
                                onClick={() => togglMenu(false)}
                            >
                                <Lang>Kapcsolat</Lang>
                                <Lang lang="en">Contact</Lang>
                            </Link>
                        </li>
                        <li className="nav-list__item">
                            <a href="#" onClick={changeLanguage} className="nav-list__link">
                                <Lang>English</Lang>
                                <Lang lang="en">Magyar</Lang>
                            </a>
                        </li>
                    </ul>
                </nav>
                <Hamburger onClick={() => togglMenu(!menuOpen)} isOpen={menuOpen} />
            </header>
            {children}
        </div>
    );
};
