import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import fbLogo from './fb_logo.png';
import Lang from '../Language/Lang';

export default () => {
    return (
        <footer className="page-footer">
            <div className="page-footer__container">
                <div className="page-footer__col page-footer__col--half">
                    <Link to="/">
                        <img src="/images/digitalbuilds-logo-inverse.svg" alt="DigitalBuilds logo" title="DigitalBuilds – Ahol az ötleted valóra válik" />
                    </Link>
                    <p>
                        <Lang>
                            Egyedi mobil és webes applikációk tervezése és elkészítése ötlettől a kész termékig. Kezdő vállalkozóknak, startupoknak, vagy már
                            működő cégeknek.
                        </Lang>
                        <Lang lang="en">
                            We design and create unique mobile and web applications, from the idea to the ready-to-use product. For budding entrepreneurs,
                            startups or companies already operating.
                        </Lang>
                    </p>
                    <p>
                        <a className="page-footer__link__social" href="https://www.facebook.com/digital.builds" target="_blank" rel="noopener noreferrer">
                            <img src={fbLogo} alt="Facebook logo" />
                        </a>
                    </p>
                </div>
                <div className="page-footer__col page-footer__col--quarter">
                    <h4 className="page-footer__heading">
                        <Lang>Oldaltérkép</Lang>
                        <Lang lang="en">Sitemap</Lang>
                    </h4>
                    <nav className="page-footer__nav">
                        <ul className="nav-list">
                            <li className="nav-list__item">
                                <Link className="nav-list__link" to="/">
                                    <Lang>Főoldal</Lang>
                                    <Lang lang="en">Home</Lang>
                                </Link>
                            </li>
                            <li className="nav-list__item">
                                <Link
                                    className="nav-list__link"
                                    to={{
                                        pathname: '/',
                                        hash: '#miert-valassz-minket',
                                    }}
                                >
                                    <Lang>Miért válassz minket?</Lang>
                                    <Lang lang="en">Why us?</Lang>
                                </Link>
                            </li>
                            <li className="nav-list__item">
                                <Link
                                    className="nav-list__link"
                                    to={{
                                        pathname: '/',
                                        hash: '#munkaink',
                                    }}
                                >
                                    <Lang>Munkáink</Lang>
                                    <Lang lang="en">Selection from previous works</Lang>
                                </Link>
                            </li>
                            <Lang>
                                <li className="nav-list__item">
                                    <Link className="nav-list__link" to="/blog">
                                        Blog
                                    </Link>
                                </li>
                            </Lang>
                            <Lang>
                                <li className="nav-list__item">
                                    <Link className="nav-list__link" to="/kviz">
                                        Kvízek
                                    </Link>
                                </li>
                            </Lang>
                            <li className="nav-list__item">
                                <Link
                                    className="nav-list__link"
                                    to={{
                                        pathname: '/',
                                        hash: '#kapcsolat',
                                    }}
                                >
                                    <Lang>Kapcsolat</Lang>
                                    <Lang lang="en">Contact</Lang>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="page-footer__col page-footer__col--quarter">
                    <h4 className="page-footer__heading">
                        <Lang>Kapcsolat</Lang>
                        <Lang lang="en">Contact</Lang>
                    </h4>
                    <p>
                        <Lang>
                            <a className="page-footer__link" href="mailto:info@digitalbuilds.hu">
                                info@digitalbuilds.hu
                            </a>
                        </Lang>
                        <Lang lang="en">
                            <a className="page-footer__link" href="mailto:info@digitalbuilds.co">
                                info@digitalbuilds.co
                            </a>
                        </Lang>
                    </p>
                    <p>
                        <a className="page-footer__link__tel" href="tel:+36304108878">
                            +36 30 410 8878
                        </a>
                    </p>
                    <p style={{ marginTop: '1em' }}>
                        <strong className="nav-list__link">DigitalBuilds Kft.</strong>
                        <br />
                        <span>
                            1158 Budapest, Bezsilla Nándor utca 24. <Lang lang="en"> - Hungary</Lang>
                        </span>
                        <br />
                        <span style={{ fontSize: '0.8em' }}>
                            <Lang>Cégjegyzékszám</Lang>
                            <Lang lang="en">Registration number</Lang>: 01-09-370273
                        </span>
                        <br />
                        <span style={{ fontSize: '0.8em' }}>
                            <Lang>Adószám: 27981803-2-42</Lang>
                            <Lang lang="en">Tax ID: HU27981803</Lang>
                        </span>
                    </p>
                </div>
            </div>
        </footer>
    );
};
