import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ title, description, url, imageUrl, publishedTime, modifiedTime }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:type" content="article" />
            <meta property="og:locale" content="hu_HU" />
            <meta property="article:published_time" content={publishedTime} />
            <meta property="article:modified_time" content={modifiedTime} />
            <meta property="article:publisher" content="https://www.facebook.com/digital.builds" />
            <meta itemprop="name" content={title} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content={imageUrl} />
        </Helmet>
    );
};
