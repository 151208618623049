import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import MetaSite from '../components/MetaSite/MetaSite';
import ContactForm from '../components/ContactForm/ContactForm';
import Lang from '../components/Language/Lang';
import LanguageContext from '../components/Language/LanguageContext';
import ArticleThumbnail from '../components/ArticleThumbnail/ArticleThumbnail';
import articles from '../articles.json';

const additionalArticleCount = 3;
const additonalArticles = articles.slice(Math.max(articles.length - additionalArticleCount, 0));

export default () => {
    const langContext = useContext(LanguageContext);
    return (
        <>
            <MetaSite
                title={langContext.currentLanguage === 'hu' ? 'DigitalBuilds – Ahol az ötleted valóra válik' : 'DigitalBuils - Where your ideas come to life'}
                description={
                    langContext.currentLanguage === 'hu'
                        ? 'Egyedi mobil és webes applikációk tervezése és elkészítése ötlettől a kész termékig. Kezdő vállalkozóknak, startupoknak, vagy már működő cégeknek.'
                        : 'We design and create unique mobile and web applications, from the idea to the ready-to-use product. For budding entrepreneurs, startups or companies already operating.'
                }
                url="https://digitalbuilds.hu"
                imageUrl="https://digitalbuilds.hu/images/digitalbuilds_1200x630.jpg"
            />
            <Header backgroundImageUrl="/images/hero-background.png">
                <section className="page-hero">
                    <div className="page-hero__container">
                        <h1>
                            <Lang>Ahol az ötleted valóra válik</Lang>
                            <Lang lang="en">Where your ideas come to life </Lang>
                        </h1>
                        <p className="page-hero__description">
                            <Lang>
                                Egyedi mobil és webes applikációk tervezése és elkészítése ötlettől a kész termékig. Kezdő vállalkozóknak, startupoknak, vagy
                                már működő cégeknek.
                            </Lang>
                            <Lang lang="en">
                                We design and create unique mobile and web applications, from the idea to the ready-to-use product. For budding entrepreneurs,
                                startups or companies already operating.
                            </Lang>
                        </p>
                        <a className="btn-primary" href="#miert-valassz-minket">
                            <Lang>Tovább</Lang>
                            <Lang lang="en">More</Lang>
                        </a>
                    </div>
                </section>
            </Header>
            <main className="page-main">
                <section className="page-main__rolunk" id="miert-valassz-minket">
                    <div className="page-main__rolunk-container">
                        <h2 className="rolunk__title">
                            <Lang>Van egy ötleted, de nem tudod, hogy kezdd el?</Lang>
                            <Lang lang="en">Do you have an idea, but you don’t know where to start? </Lang>
                        </h2>
                        <p className="rolunk__description">
                            <Lang>
                                Segítünk elindítani online vállalkozásodat! Kidolgozunk és kezedbe adunk egy MVP*-t, amit kipróbálhatsz a piacon, hogy minél
                                hamarabb meggyőződhess ötleted életképességéről.
                            </Lang>
                            <Lang lang="en">
                                We help you start up your online business! We create and give you an MVP* which you can test on the market, so you can be sure
                                of the viability of your idea as soon as possible.
                            </Lang>
                        </p>
                        <ul className="product-list">
                            <li className="product-list__item product-list__item--web">
                                <h3>
                                    <Lang>
                                        Honlapok &<br />
                                        Webalkalmazások
                                    </Lang>
                                    <Lang lang="en">
                                        Websites &<br />
                                        Web applications
                                    </Lang>
                                </h3>
                                <p>
                                    <Lang>
                                        A legfrisebb technológiákon alapuló reszponzív honlapok és webalkalmazások készítése, online vállalkozásod akár
                                        teljeskörű kiszolgálása érdekében
                                    </Lang>
                                    <Lang lang="en">
                                        Creation of responsive websites and web applications with the latest technology, for providing full service to your
                                        online business.
                                    </Lang>
                                </p>
                            </li>
                            <li className="product-list__item product-list__item--mobile">
                                <h3>
                                    <Lang>
                                        Mobil & Okoseszköz
                                        <br />
                                        Alkalmazások
                                    </Lang>
                                    <Lang lang="en">
                                        Apps for Mobile &
                                        <br />
                                        Smart device
                                    </Lang>
                                </h3>
                                <p>
                                    <Lang>
                                        iOS, Android és platformfüggetlen mobilalkalmazások fejlesztése. Modern, letisztult felhasználói felület, kiváló
                                        felhasználói élmény.
                                    </Lang>
                                    <Lang lang="en">
                                        Development of iOS, Android and platform-independent mobile applications. Modern, distilled user interface, excellent
                                        user experience.
                                    </Lang>
                                </p>
                            </li>
                            <li className="product-list__item product-list__item--consultancy">
                                <h3>
                                    <Lang>
                                        Konzultáció &<br />
                                        Tanácsadás
                                    </Lang>
                                    <Lang lang="en">
                                        Consultation &<br />
                                        Advising
                                    </Lang>
                                </h3>
                                <p>
                                    <Lang>
                                        Segítünk az ötleted megvalósulásában, tapasztalatunkkal és tanácsainkkal támagatjuk a projektet, hogy az a lehető
                                        legjobb minőségben és tartalommal készüljön el.
                                    </Lang>
                                    <Lang lang="en">
                                        We can help you implement your ideas, we support your project with our experience and advice, so that whatever your
                                        product, it is created in the most proficient quality and with the best possible content.
                                    </Lang>
                                </p>
                            </li>
                        </ul>
                        <p className="rolunk__mvp">
                            <small>
                                <Lang>
                                    *Az angol MVP mozaikszó a "Minimum Viable Product" kifejezést takarja. Ez egy olyan terméket jelent, amely épp annyi
                                    funkciót tartalmaz, amennyivel az első néhány vásárló már elégedett tud lenni. Mindezek mellett hasznos visszajelzéseket
                                    szolgáltathat a jövőbeli fejlesztések irányát illetően.
                                </Lang>
                                <Lang lang="en">
                                    *Acronym: “Minimum Viable Product”. It means such a product that contains just as many functions as necessary to satisfy the
                                    first couple of customers. Besides, it may render useful feedback as to future development.{' '}
                                </Lang>
                            </small>
                        </p>
                    </div>
                </section>

                <section>
                    <div className="how-we-work__container">
                        <h2 className="how-we-work__title">
                            <Lang>Miért válassz minket?</Lang>
                            <Lang lang="en">Why us?</Lang>
                        </h2>
                        <ul className="how-we-work__list">
                            <li className="how-we-work__item">
                                <Lang>
                                    <strong>Sokéves tapasztalat</strong> professzionális, nagyvállalati környezetben
                                </Lang>
                                <Lang lang="en">
                                    <strong>Multiyear experience</strong> in professional, large corporate environment
                                </Lang>
                            </li>
                            <li className="how-we-work__item">
                                <Lang>
                                    Nem csak fejlesztünk, ötletekkel, tapasztalatunkkal
                                    <strong> támogatjuk a projektek megvalósulását</strong>
                                </Lang>
                                <Lang lang="en">
                                    We are not only developers, <strong>we support the implementation of your project with our ideas and experience</strong>
                                </Lang>
                            </li>
                            <li className="how-we-work__item">
                                <Lang>
                                    <strong>Reális árakon, reális határidőkkel</strong> dolgozunk
                                </Lang>
                                <Lang lang="en">
                                    <strong>Fair prices and realistic deadlines</strong>
                                </Lang>
                            </li>
                            <li className="how-we-work__item">
                                <Lang>
                                    Segíteni szeretnénk az <strong>ötleted mihamarabbi megvalósulását</strong>, piacra kerülését
                                </Lang>
                                <Lang lang="en">
                                    We assist with the <strong>swift realization</strong> and marketing of your idea
                                </Lang>
                            </li>
                        </ul>
                    </div>
                </section>

                <section className="page-main__references" id="munkaink">
                    <div className="references-container">
                        <h2>
                            <Lang>Korábbi munkáinkból</Lang>
                            <Lang lang="en">Selection from our previous works </Lang>
                        </h2>
                        <div className="references-list">
                            <div className="references-item">
                                <div className="references-image">
                                    <img alt="PodPad - Podcast portál" src="/images/portfolio/podpad@2x.jpg" />
                                </div>
                                <div className="references-meta">
                                    <div className="references-description">
                                        <h4>
                                            <Lang>PodPad - Podcast portál</Lang>
                                            <Lang lang="en">PodPad - Podcast portal</Lang>
                                        </h4>
                                        <p className="references__tags">
                                            <Lang>design, webalkalmazás, tanácsadás</Lang>
                                            <Lang lang="en">design, web application, advising</Lang>
                                        </p>
                                    </div>
                                    <a href="https://podpad.hu/" target="_blank" rel="noopener noreferrer" className="btn-default btn-small">
                                        <Lang>Megtekintés</Lang>
                                        <Lang lang="en">View</Lang>
                                    </a>
                                </div>
                            </div>
                            <div className="references-item">
                                <div className="references-image">
                                    <img alt="Incorpora Állásportál" src="/images/portfolio/portfolio-solarbnb@2x.png" />
                                </div>
                                <div className="references-meta">
                                    <div className="references-description">
                                        <h4>SolarBnb</h4>
                                        <p className="references__tags">
                                            <Lang>webalkalmazás, tanácsadás</Lang>
                                            <Lang lang="en">web application, advising</Lang>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="references-item">
                                <div className="references-image">
                                    <img alt="Incorpora Állásportál" src="/images/portfolio/portfolio-mentor@2x.png" />
                                </div>
                                <div className="references-meta">
                                    <div className="references-description">
                                        <h4>Mentor Stories</h4>
                                        <p className="references__tags">
                                            <Lang>design, webalkalmazás, tanácsadás</Lang>
                                            <Lang lang="en">design, web application, advising</Lang>
                                        </p>
                                    </div>
                                    <a href="https://mentorstories.com/" target="_blank" rel="noopener noreferrer" className="btn-default btn-small">
                                        <Lang>Megtekintés</Lang>
                                        <Lang lang="en">View</Lang>
                                    </a>
                                </div>
                            </div>
                            <div className="references-item">
                                <div className="references-image">
                                    <img alt="Unimatch portál" src="/images/portfolio/portfolio-unimatch@2x.png" />
                                </div>
                                <div className="references-meta">
                                    <div className="references-description">
                                        <h4>
                                            <Lang>Unimatch portál</Lang>
                                            <Lang lang="en">Unimatch portal</Lang>
                                        </h4>
                                        <p className="references__tags">
                                            <Lang>design, webalkalmazás, tanácsadás</Lang>
                                            <Lang lang="en">design, web application, advising</Lang>
                                        </p>
                                    </div>
                                    <a href="https://unimatch.world/" target="_blank" rel="noopener noreferrer" className="btn-default btn-small">
                                        <Lang>Megtekintés</Lang>
                                        <Lang lang="en">View</Lang>
                                    </a>
                                </div>
                            </div>
                            <div className="references-item">
                                <div className="references-image">
                                    <img alt="Incorpora Állásportál" src="/images/portfolio/portfolio-incorpora@2x.png" />
                                </div>
                                <div className="references-meta">
                                    <div className="references-description">
                                        <h4>Incorpora Állásportál</h4>
                                        <p className="references__tags">
                                            <Lang>design, webalkalmazás, tanácsadás</Lang>
                                            <Lang lang="en">design, web application, advising</Lang>
                                        </p>
                                    </div>
                                    <a href="https://incorporajobs.hu/" target="_blank" rel="noopener noreferrer" className="btn-default btn-small">
                                        <Lang>Megtekintés</Lang>
                                        <Lang lang="en">View</Lang>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Lang>
                    <section className="page-blog__additional-articles">
                        <div className="page-blog__centered-container">
                            <h2>DigitalBuilds Blog</h2>
                            <div className="row">
                                {additonalArticles.map(
                                    ({
                                        title: additionalArticleTitle,
                                        description: additionalArticleDescription,
                                        publishedTime: additionalArticlePublishedTime,
                                        publishedTimeString: additionalArticlePublishedTimeString,
                                        metaImageUrl: additionalArticleMetaImageUrl,
                                        url: additionalArticleUrl,
                                    }) => {
                                        return (
                                            <ArticleThumbnail
                                                key={`additional-article-${additionalArticleUrl}`}
                                                title={additionalArticleTitle}
                                                description={additionalArticleDescription}
                                                publishedTime={additionalArticlePublishedTime}
                                                publishedTimeString={additionalArticlePublishedTimeString}
                                                metaImageUrl={additionalArticleMetaImageUrl}
                                                url={additionalArticleUrl}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                        <div className="page-main__more-articles">
                            <Link className="page-blog__link" to="/blog">
                                Összes cikk {'>'}
                            </Link>
                        </div>
                    </section>
                </Lang>
                <section className="page-main__partners" id="partners">
                    <div className="partners-container">
                        <h2>
                            <Lang>Akikkel együtt dolgoztunk</Lang>
                            <Lang lang="en">Our partners</Lang>
                        </h2>
                        <ul className="partners-list">
                            <li className="partners-list__item maltai">
                                <img src="/images/partners/partner-maltai@2x.png" alt="Magyar Máltai Szeretetszolgálat" />
                            </li>
                            <li className="partners-list__item paralimpiai">
                                <img src="/images/partners/partner-paralimpiai@2x.png" alt="Magyar Paralimpiai Bizottság" />
                            </li>
                            <li className="partners-list__item incorpora">
                                <img src="/images/partners/partner-incorpora@2x.png" alt="Incorpora Hálózat" />
                            </li>
                            <li className="partners-list__item atmedia">
                                <img src="/images/partners/partner-atmedia@2x.png" alt="atmedia" />
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="page-main__contact" id="kapcsolat">
                    <div className="page-main__contact-container">
                        <h2>
                            <Lang>Kapcsolat</Lang>
                            <Lang lang="en">Contact</Lang>
                        </h2>
                        <p className="contact__description">
                            <Lang>Add meg az elérhetőségedet és hamarosan kapcsolatba lépünk veled, hogy elkezdhessük a közös munkát.</Lang>
                            <Lang lang="en">
                                Please leave your contact information and we will get back to you promptly, to start working on your project together.
                            </Lang>
                        </p>
                        <ContactForm />
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};
