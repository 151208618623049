import React from 'react';

import articles from '../../articles.json';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MetaArticle from '../MetaArticle/MetaArticle';
import ContactForm from '../ContactForm/ContactForm';
import ArticleThumbnail from '../ArticleThumbnail/ArticleThumbnail';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const additionalArticleCount = 6;
const pageUrl = 'https://digitalbuilds.hu';

export default ({ children, title, description, url, metaImageUrl, backgroundImageUrl, publishedTime, publishedTimeString }) => {
    const additonalArticles = articles.filter((article) => article.url !== url).slice(0, additionalArticleCount);
    return (
        <>
            <ScrollToTop />
            <MetaArticle
                title={title.replace('&shy;', '')}
                description={description}
                url={`${pageUrl}${url}`}
                imageUrl={`${pageUrl}${metaImageUrl}`}
                publishedTime={publishedTime}
                modifiedTime={publishedTime}
            />
            <Header backgroundImageUrl={backgroundImageUrl}>
                <section className="page-hero">
                    <div className="page-hero__container">
                        <div className="page-blog__published">
                            <time className="page-blog__published-item" dateTime={publishedTime}>
                                {publishedTimeString}
                            </time>
                        </div>
                        <h1
                            dangerouslySetInnerHTML={{
                                __html: title,
                            }}
                            className="page-blog__title"
                        />
                        <p className="page-hero__subtitle">{description}</p>
                    </div>
                </section>
            </Header>
            <main className="page-blog">
                {children}
                <section className="page-blog__centered">
                    <div className="page-blog__centered-container">
                        <h3>Mit csinálunk?</h3>
                        <p className="page-blog__paragraph">
                            Foglalkozunk testreszabott, egyedi kivitelezésű <strong>weboldal</strong>, <strong>webalkalmazás</strong> fejlesztéssel.{' '}
                            <strong>Webshop</strong>, <strong>mobil app</strong> készítésével. <strong>Okos eszköz</strong>ökre szánt szoftverek írásával.
                            Minden új alkalmazás, amit fejlesztünk zökkenőmentesen skálázódik, néhány darab felhasználótól kezdve, a több 10-100 ezres
                            felhasználó számig.
                        </p>
                    </div>
                </section>
                <section className="page-blog__contact" id="kapcsolat">
                    <div className="page-blog__contact-container">
                        <p className="contact__description">
                            Ha szeretnél többet megtudni, vagy úgy gondolod, számodra mi lehetünk a megfelelő választás céljaid eléréséhez, vedd fel velünk a
                            kapcsolatot:
                        </p>
                        <ContactForm />
                    </div>
                </section>
                <section className="page-blog__centered why-contact">
                    <div className="page-blog__centered-container">
                        <h3>Mikor lépj velünk kapcsolatba?</h3>
                        <ul className="why-contact__list">
                            <li className="why-contact__item">
                                Ha <em className="page-blog__em">új vállalkozásba</em> kezdenél vagy a meglévőt fejlesztenéd
                            </li>
                            <li className="why-contact__item">
                                Ha vállalkozásodhoz <em className="page-blog__em">online szakértelemre</em> van szükséged
                            </li>
                            <li className="why-contact__item">
                                Ha a megbízottjaidat szívesen kezeled <em className="page-blog__em">partnerként</em>
                            </li>
                            <li className="why-contact__item">
                                Nyitott vagy több éves, professzionális tapasztalatra építkező <em className="page-blog__em">tanácsokra</em>
                            </li>
                            <li className="why-contact__item">
                                Szeretnél reális és <em className="page-blog__em">transzparens</em> célokkal, illetve határidőkkel dolgozni
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="page-blog__additional-articles">
                    <div className="page-blog__centered-container">
                        <h3>További cikkeink</h3>
                        <div className="row">
                            {additonalArticles.map(
                                ({
                                    title: additionalArticleTitle,
                                    description: additionalArticleDescription,
                                    publishedTime: additionalArticlePublishedTime,
                                    publishedTimeString: additionalArticlePublishedTimeString,
                                    metaImageUrl: additionalArticleMetaImageUrl,
                                    url: additionalArticleUrl,
                                }) => {
                                    return (
                                        <ArticleThumbnail
                                            key={`additional-article-${additionalArticleUrl}`}
                                            title={additionalArticleTitle}
                                            description={additionalArticleDescription}
                                            publishedTime={additionalArticlePublishedTime}
                                            publishedTimeString={additionalArticlePublishedTimeString}
                                            metaImageUrl={additionalArticleMetaImageUrl}
                                            url={additionalArticleUrl}
                                        />
                                    );
                                }
                            )}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};
