import React from 'react';
import './ContactForm.scss';
import classnames from 'classnames';
import Lang from '../Language/Lang';
import LanguageContext from '../Language/LanguageContext';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// eslint-disable-next-line no-useless-escape
const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
const maxLastNameLength = 25;
const maxFirstNameLength = 25;
const maxMessageLength = 500;

const postContactFormDataAsync = data =>
    fetch('/api/ContactForm', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }).then(response => response.json());

const isErrorMap = {
    lastName: value => value.length > maxLastNameLength,
    firstName: value => value.length > maxFirstNameLength,
    email: value => !emailRegex.test(value),
    phone: value => value && !phoneRegex.test(value),
    message: value => value.length > maxMessageLength,
};

const getFormValidationData = fieldValues => {
    const fieldErrors = Object.keys(fieldValues).reduce((acc, fieldName) => {
        acc[fieldName] = isErrorMap[fieldName](fieldValues[fieldName]);
        return acc;
    }, {});
    const isFormValid = Object.keys(fieldErrors).reduce((acc, fieldName) => acc && !fieldErrors[fieldName], true);
    return {
        fieldErrors,
        isFormValid,
    };
};

const formStates = {
    submitted: 'submitted',
    errored: 'errored',
    loading: 'loading',
};

const getFieldClass = isErrored => classnames('contact-form__field', { 'contact-form__field--invalid': isErrored });

class ContactForm extends React.Component {
    state = {
        lastNameValue: '',
        firstNameValue: '',
        emailValue: '',
        phoneValue: '',
        messageValue: '',
        lastNameError: false,
        firstNameError: false,
        emailError: false,
        phoneError: false,
        messageError: false,
        isFormValid: true,
        formState: undefined,
    };

    handleChange = event => {
        const {
            target: { name, value },
        } = event;
        this.setState(() => ({
            [name]: value,
        }));
    };

    handleSubmit = event => {
        event.preventDefault();
        const { lastNameValue: lastName, firstNameValue: firstName, emailValue: email, phoneValue: phone, messageValue: message } = this.state;
        const { fieldErrors, isFormValid } = getFormValidationData({
            lastName,
            firstName,
            email,
            phone,
            message,
        });
        const { lastName: lastNameError, firstName: firstNameError, email: emailError, phone: phoneError, message: messageError } = fieldErrors;
        this.setState(() => ({
            lastNameError,
            firstNameError,
            emailError,
            phoneError,
            messageError,
            isFormValid,
        }));
        if (!isFormValid) {
            return;
        }
        this.setState(() => ({
            formState: formStates.loading,
        }));
        postContactFormDataAsync({
            lastName,
            firstName,
            email,
            phone,
            message,
        })
            .then(() => {
                this.setState(() => ({ formState: formStates.submitted }));
            })
            .catch(() => {
                this.setState(() => ({ formState: formStates.errored }));
            });
    };

    render() {
        const {
            lastNameValue,
            firstNameValue,
            emailValue,
            phoneValue,
            messageValue,
            lastNameError,
            firstNameError,
            emailError,
            phoneError,
            messageError,
            isFormValid,
            formState,
        } = this.state;
        const isSubmitButtonDisabled = formState === formStates.loading;
        const isFormLoading = formState === formStates.loading;
        const isFormSubmitted = formState === formStates.submitted;
        const isFormErrored = formState === formStates.errored;
        const isInputDisabled = isFormLoading || isFormSubmitted;
        const formClasses = classnames('contact-form', { invalid: !isFormValid });
        return (
            <form className={formClasses} noValidate onSubmit={this.handleSubmit}>
                {!isFormValid && (
                    <div className="contact-form__alert contact-form__alert--warning">
                        <Lang>Néhány mezőt nem megfelelően töltöttél ki!</Lang>
                        <Lang lang="en">Some fields have errors</Lang>
                    </div>
                )}
                {isFormLoading && (
                    <div className="contact-form__alert contact-form__alert--info">
                        <Lang>Küldés...</Lang>
                        <Lang lang="en">Sending...</Lang>
                    </div>
                )}
                {isFormErrored && (
                    <div className="contact-form__alert contact-form__alert--error">
                        <Lang>Hiba az üzenet kézbesítése során, próbáld újra később!</Lang>
                        <Lang lang="en">There was an error while sending message, please try again later!</Lang>
                    </div>
                )}
                {isFormSubmitted && (
                    <div className="contact-form__alert contact-form__alert--success">
                        <Lang>Az üzenetet kézbesítettük!</Lang>
                        <Lang lang="en">Your message has been delivered!</Lang>
                    </div>
                )}
                <div className="contact-form__fields">
                    <div className="contact-form__field-container">
                        <input
                            className={getFieldClass(lastNameError)}
                            placeholder={this.context.currentLanguage === 'hu' ? 'Vezetéknév' : 'First name'}
                            type="text"
                            value={lastNameValue}
                            name="lastNameValue"
                            onChange={this.handleChange}
                            disabled={isInputDisabled}
                            maxLength={maxLastNameLength}
                        />
                        {lastNameError && (
                            <small className="contact-form__error-message">
                                <Lang>Maximum {maxLastNameLength} karakter.</Lang>
                                <Lang lang="en">{maxLastNameLength} characters maximum</Lang>
                            </small>
                        )}
                    </div>
                    <div className="contact-form__field-container">
                        <input
                            className={getFieldClass(firstNameError)}
                            placeholder={this.context.currentLanguage === 'hu' ? 'Keresztnév' : 'Last name'}
                            type="text"
                            value={firstNameValue}
                            name="firstNameValue"
                            onChange={this.handleChange}
                            disabled={isInputDisabled}
                            maxLength={maxFirstNameLength}
                        />
                        {firstNameError && (
                            <small className="contact-form__error-message">
                                <Lang>Maximum {maxFirstNameLength} karakter.</Lang>
                                <Lang lang="en">{maxFirstNameLength} characters maximum</Lang>
                            </small>
                        )}
                    </div>
                    <div className="contact-form__field-container">
                        <input
                            className={getFieldClass(emailError)}
                            placeholder="Email"
                            type="email"
                            value={emailValue}
                            name="emailValue"
                            onChange={this.handleChange}
                            disabled={isInputDisabled}
                        />
                        {emailError && (
                            <small className="contact-form__error-message">
                                <Lang>Add meg az email címedet megfelelő formátumban. </Lang>
                                <Lang lang="en">Please enter a valid email address</Lang>
                            </small>
                        )}
                    </div>
                    <div className="contact-form__field-container">
                        <input
                            className={getFieldClass(phoneError)}
                            placeholder={this.context.currentLanguage === 'hu' ? 'Telefon' : 'Phone'}
                            type="phone"
                            value={phoneValue}
                            name="phoneValue"
                            onChange={this.handleChange}
                            disabled={isInputDisabled}
                        />
                        {phoneError && (
                            <small className="contact-form__error-message">
                                <Lang>Nem megfelelő formátum.</Lang>
                                <Lang lang="en">Wrong phone number format</Lang>
                            </small>
                        )}
                    </div>
                    <div className="contact-form__field-container contact-form__field-container--message">
                        <textarea
                            className={getFieldClass(messageError)}
                            placeholder={this.context.currentLanguage === 'hu' ? 'Üzenet' : 'Message'}
                            value={messageValue}
                            name="messageValue"
                            onChange={this.handleChange}
                            disabled={isInputDisabled}
                            maxLength={maxMessageLength}
                        />
                        {messageError && (
                            <small className="contact-form__error-message">
                                <Lang>Maximum {maxMessageLength} karakter.</Lang>
                                <Lang lang="en">{maxMessageLength} characters maximum.</Lang>
                            </small>
                        )}
                    </div>
                </div>
                {!isFormSubmitted && (
                    <button className="btn-default contact-form__send-button" disabled={isSubmitButtonDisabled} type="submit">
                        <Lang>Üzenet küldése</Lang>
                        <Lang lang="en">Send message</Lang>
                    </button>
                )}
            </form>
        );
    }
}

ContactForm.contextType = LanguageContext;

export default ContactForm;
