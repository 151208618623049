import React from 'react';

import { Link } from 'react-router-dom';

export default () => {
    return (
        <>
            <section className="page-blog__text">
                <div className="page-blog__text-container page-blog__text-container--columns">
                    <p className="page-blog__paragraph page-blog__intro">
                        Az előző bejegyzésben arról volt szó, hogyan vezethet webapp vagy mobilapp ötleted sikeréhez a megfelelő priorizálás és mi az MVP
                        fogalma. (Az előző cikkünk:{' '}
                        <Link className="page-blog__link" to="/blog/ne-epits-csillagrombolot">
                            Ne építs csillagrombolót!
                        </Link>
                        ) Most azt szeretnénk megindokolni mennyi idő egy ilyen MVP elkészítése app/webapp esetében, illetve miért pont ennyi?
                    </p>
                    <p className="page-blog__paragraph">
                        Egy weboldal vagy alkalmazás fejlesztése sokak számára homályos terület lehet. Ha webalkalmazást vagy weboldalt még nem raktál össze, az
                        alábbi elképzelések tipikusak:
                    </p>
                    <ul>
                        <li>Biztos erre is van egy program, mint a szövegszerkesztő vagy a diavetítés-készítő</li>
                        <li>A programot megnyitod, egérrel összekattintgatod a weboldal vázát, beállítod a színeket és kész is</li>
                    </ul>
                    <p className="page-blog__paragraph">
                        A valóság csupán részben ilyen. Vannak ilyen programok, online elérhetőek, viszont limitálják a lehetőségeidet: Pl.: Ezt a gombot odébb
                        szeretnéd rakni? Lehet, hogy az adott programban ezt pont nem lehet.
                    </p>
                    <p className="page-blog__paragraph">
                        <b>Nincs tetszőlegesen egyedi kivitelezésű alkalmazás vagy weboldal készítő grafikus program.</b> Minden egyedi megrendeléshez programkódokat
                        kell írni. Kézzel írt programkódok vezérlik majd az alkalmazás vagy weboldal funkcióit, megjelenését, a felhasználói interakciókra adott
                        történéseket. A programkód maga pedig nem más, mint gépi utasítások és vezérlési szerkezetek egymásutánja. Egy programozó feladata pedig
                        az általad kitalált ötlet funkciókra bontása, majd azok programkódra = "a gép által értelmezhető nyelvre" fordítása.
                    </p>
                    <p className="page-blog__paragraph">
                        Ez a szakma sok év tapasztalatot és megfelelő, úgy nevezett algoritmikus gondolkodásmódot igényel. Maga, a programírás, mint feladat,
                        időigényes, ezért is fontos az ötletedben szereplő funkciókat fontossági sorrendbe állítani, majd elsőként azokon dolgozni, amik
                        kulcsfontosságúak. Így tudsz minél gyorsabban piaci visszajelzést szerezni.
                    </p>
                    <p className="page-blog__paragraph page-blog__intro">
                        Tapasztalat alapján úgy látjuk, hogy egy jól működő, pont elegendő mennyiségű funkciót tartalmazó app / webapp MVP elkészítési ideje 1-3
                        hónap.
                    </p>
                    <p className="page-blog__paragraph">
                        Ez egy olyan időbeli határ, ami alatt egy jól kidolgozott ötletből egy egyedi fejlesztésű webapp vagy mobilapp elkészülhet. Az időbeli
                        szállítást az tudja garantálni, hogy tapasztalat alapján megbecsüljük az egyes funkciók fejlesztése mennyi időt vehet igénybe, majd
                        ennek megfelelően, közös megállapodással összegyűjtjük a legszükségesebbeket, melyek beleférnek az 1-3 hónapos időkeretbe.
                    </p>
                    <p className="page-blog__paragraph">
                        Ha szeretnél többet megtudni a részletekről, vagy bármilyen kérdésed/észrevételed van, fordulj hozzánk bizalommal az alábbi elérhetőségeken:
                    </p>
                    <p className="page-blog__paragraph">
                        {' '}
                        <a className="page-blog__link" href="mailto:info@digitalbuilds.hu">
                            info@digitalbuilds.hu
                        </a>{' '}
                        /{' '}
                        <a className="page-blog__link" href="https://www.facebook.com/digital.builds" target="_blank">
                            Facebook
                        </a>{' '}
                        /{' '}
                        <a className="page-blog__link" href="tel:+36304108878">
                            +36 30 410 8878
                        </a>
                    </p>
                    <p className="page-blog__paragraph">
                        Szívesen ránk bíznád ötleted technikai megvalósítását? Keress minket bizalommal, vedd fel velünk a{' '}
                        <a className="page-blog__link" href="#kapcsolat">
                            kapcsolat
                        </a>
                        ot!
                    </p>
                </div>
            </section>
        </>
    );
};
