import React from 'react';
import './Hamburger.scss';

const Hamburger = props => {
    const { onClick, isOpen } = props;
    const classes = isOpen ? 'hamburger hamburger__open' : 'hamburger';
    return (
        <div className={classes} onClick={onClick}>
            <div></div>
        </div>
    );
};

export default Hamburger;
