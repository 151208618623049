import React from 'react';

import { Link } from 'react-router-dom';

export default () => {
    return (
        <>
            <section className="page-blog__text">
                <div className="page-blog__text-container page-blog__text-container--columns">
                    <p className="page-blog__paragraph page-blog__intro">
                        Felkelés, reggeli, majd egyből az irodába. Már ha a reggelire egyáltalán van időd és nem egyből egy telefonhívásra reagálsz. Bent a
                        munkahelyen teszed a dolgod, ahogy egy jó alkalmazottnak kell. Talán túl is vállalod magad és mire hazaérsz egy plusz órát a hátad
                        mögött hagyva, úgy érzed még csak most kezdődik a nap.
                    </p>
                    <p className="page-blog__paragraph">
                        A szobában kisebb rendetlenség, amit tegnap akartál holnap megcsinálni, de érzed, hogy már ma nem lesz hozzá energiád, így majd inkább
                        holnap. És így megy ez a hét végéig. A szombat persze a párodé, vagy a haveroké. Meg persze az idő is túl jó, hogy bent töltsd. Szóval
                        vasárnap szépen meg is csinálod, amit szeretnél, a partnereddel, mint közös program. Aki természetesen élvezi, hogy veled lehet, de ha
                        régóta ilyenek a napok, elhiheted, valószínű unja már a látszólag véget nem érő körforgást.
                    </p>
                    <img className="page-blog__article-image" src="/images/blog/iroda.jpg" alt="Iroda" />
                    <p className="page-blog__paragraph">
                        Ez az jobbik, eset, amikor nem vállaltál be baráti alapon segítséget senkinek vagy nem kezdtél saját kis projektekbe munka mellett.
                        Valójában a sokkal jobbik eset, mert persze lehet, hogy már gyerekeid vannak és az időd jelentős részét érthető módon rájuk szánod. Egy
                        szó, mint száz, biztos gondolkodtál már rajta, milyen jó is lenne, ha lenne még egy kis időd azokra a dolgokra, amikre még gondolni sem
                        jutott időd, számos más dolog mellett, amit még, amúgyis meg kell csinálnod. Hogyan férhet bele minden más? Sajnos az a szomorú helyzet,
                        hogy a látszólag véget nem érő körforgás tényleg soha véget nem érő.
                    </p>
                    <p className="page-blog__paragraph">
                        Talán mikor nyugdíjba mész, akkor. Már ha lesz nyugdíjad, vagy leszel olyan egészséges, hogy élvezd és ne a kórházban panaszkodj a
                        kedvenc panasz partnereddel.
                    </p>
                    <p className="page-blog__paragraph">
                        Értelemszerűen jön a kérdés, mit lehet ilyen helyzetben tenni? Hogyan lehet tényleg kitörni a mókuskerékből? A válasz rémisztő lehet:
                        Kezdj el lassan a magad urává válni. Indíts vállalkozást. Várj, inkább reagálok helyetted: "Áh, túl kockázatos, nem tehetem meg". Ha
                        ilyesmi választ adtál magadban, javaslom a{' '}
                        <Link className="page-blog__link" to="/blog/multi-cegek-es-a-biztonsag-illuzioja">
                            Multi cégek és a biztonság illúziója
                        </Link>{' '}
                        rövid cikket tőlünk. Nyilván való, hogy a változás nem egyszerű, és nem is egyből kivitelezhető. Rengeteg olyan tényezővel kell
                        számolnod, amire csak a tapasztalat készíthet fel, hangozzon ez bármennyire is klisésen.
                    </p>
                    <p className="page-blog__paragraph">
                        Szerencsére sokan járunk hasonló cipőben, így tudunk egymásnak segíteni. A{' '}
                        <Link className="page-blog__link" to="/">
                            DigitalBuilds
                        </Link>{' '}
                        vállalkozást, például azért hoztuk létre amiért te is létrehoznád: Hogy a magunk urai legyünk, és megmutassuk NEKED, igen is meg lehet
                        csinálni. Egy kis ingyen segítség, ha esetleg kételkedsz abban, hogy az alkalmazottként töltött 8 órádat, a jó ügyért költöd-e el:
                        Gondolkozz és dolgozz olyan ügyért, amit helyesnek érzel. Az így felszabadult 8 órát olyan dolgokra tudod majd szánni, amiket igazán
                        fontosnak tartasz. Ha esetleg már most előrébb jársz ennél, és egész véletlenül, van is egy{' '}
                        <strong>stabil online jelenlétet igénylő vállalkozásötleted</strong>, fontold meg, hogy velünk dolgozz. Miért? Mert tudjuk mennyi
                        dolgod van, mi is hasonló helyzetben voltunk.
                    </p>
                </div>
            </section>
        </>
    );
};
