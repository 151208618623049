import React from 'react';
import { Link } from 'react-router-dom';

export default ({ title, description, image, url }) => {
    return (
        <div className="col article-thumbnail">
            <div className="article-thumbnail__image-wrapper">
                <Link to={url}>
                    <img className="article-thumbnail__image" alt={title} src={image} />
                </Link>
            </div>
            <Link className="page-blog__link" to={url}>
                <h4 className="article-thumbnail__title">{title}</h4>
            </Link>
            <p className="article-thumbnail__description">{description}</p>
        </div>
    );
};
