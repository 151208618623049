import React, { useContext } from 'react';
import LanguageContext from './LanguageContext';
import PropTypes from 'prop-types';

const Lang = ({ children, lang }) => {
    const langContext = useContext(LanguageContext);
    return langContext.currentLanguage === lang && <>{children}</>;
};

Lang.propTypes = {
    lang: PropTypes.oneOf(['hu', 'en']),
};

Lang.defaultProps = {
    lang: 'hu',
};

export default Lang;
