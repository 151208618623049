import React from 'react';
import { Link } from 'react-router-dom';

import articles from '../../articles.json';
import quizes from '../../quizes.json';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MetaSite from '../MetaSite/MetaSite';
import ContactForm from '../ContactForm/ContactForm';
import ArticleThumbnail from '../ArticleThumbnail/ArticleThumbnail';
import QuizThumbnail from '../QuizThumbnail/QuizThumbnail';

const articleCount = 6;
const quizCount = 6;
const pageUrl = 'https://digitalbuilds.hu';
const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const isEmail = (email) => {
    return emailRegex.test(email);
};

const getScore = (originalQuestions, answers) => {
    const totalQuestions = originalQuestions.length;
    const correctQuestions = originalQuestions.reduce((acc, question) => {
        const [{ id: correctAnswerId } = {}] = question.answers.filter(({ isCorrect }) => isCorrect);
        if (answers[question.id] === correctAnswerId) {
            return acc + 1;
        }
        return acc;
    }, 0);
    const score = (correctQuestions / totalQuestions) * 100;
    return Math.round(score);
};

const submitLead = (email) => {
    return fetch('/api/Lead', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
    }).then((response) => response.json());
};

const getSuccessMessage = (resultTexts, score) => {
    const [{ successText = '' } = {}] = resultTexts.filter(({ minLimit, maxLimit }) => score >= minLimit && score <= maxLimit);
    return successText.replace('{{result}}', String(score));
};

export default ({ url, metaTitle, metaDescription, metaImage, title, description, image, questions, resultTexts }) => {
    const [state, setState] = React.useState({
        email: '',
        showSuccess: false,
        isCopied: false,
        questions: {},
        reasonings: {},
        score: 0,
        acceptedTerms: false,
    });
    const kvizLink = React.createRef();
    const handleResoningToggle = (questionId) => {
        setState({
            ...state,
            reasonings: {
                ...state.reasonings,
                [questionId]: !state.reasonings[questionId],
            },
        });
    };
    const handleAnswerChange = (questionId, answerId) => {
        setState({
            ...state,
            questions: {
                ...state.questions,
                [questionId]: answerId,
            },
        });
    };
    const handleEmailChange = ({ target: { value: email } }) => {
        setState({ ...state, email });
    };
    const handleAcceptTermsChange = () => {
        setState({ ...state, acceptedTerms: !state.acceptedTerms });
    };
    const handleSubmit = () => {
        if (isEmail(state.email) && state.acceptedTerms) {
            submitLead(state.email);
        }
        setState({
            ...state,
            score: getScore(questions, state.questions),
            showSuccess: true,
        });
    };
    const handleCopyClick = () => {
        const copyText = kvizLink.current;
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        setState({
            ...state,
            isCopied: true,
        });
    };
    const additonalArticles = articles.slice(0, articleCount);
    const additonalQuizes = quizes.filter((quiz) => quiz.url !== url).slice(0, quizCount);
    return (
        <>
            <MetaSite title={metaTitle} description={metaDescription} url={`${pageUrl}${url}`} imageUrl={`${pageUrl}${metaImage}`} />
            <Header />
            <main className="page-kviz">
                <section className="page-kviz__hero">
                    <div className="page-kviz__hero-container">
                        <h1 className="page-kviz__hero-title">{title}</h1>
                        <p className="page-kviz__hero-description">{description}</p>
                        <img className="page-kviz__hero-image" src={image} alt="Kvíz" />
                    </div>
                </section>
                <section className="page-kviz__questions">
                    <div className="page-kviz__questions-container">
                        <ol className="page-kviz__questions-list">
                            {questions.map((question, questionIndex) => {
                                return (
                                    <li key={`question-${question.id}`} className="question">
                                        <h2 className="question__title">
                                            {`${questionIndex + 1}. `}
                                            {question.title}
                                        </h2>
                                        <ul className="question__answers-list">
                                            {question.answers.map((answer) => {
                                                return (
                                                    <li key={`answer-${question.id}-${answer.id}`}>
                                                        {state.showSuccess && answer.isCorrect && (
                                                            <img className="question__answer-icon" src="/images/done-micon.svg" alt="Jó válasz jelzés" />
                                                        )}
                                                        {state.showSuccess && !answer.isCorrect && (
                                                            <img className="question__answer-icon" src="/images/close-micon.svg" alt="Rossz válasz jelzés" />
                                                        )}
                                                        <input
                                                            type="radio"
                                                            id={answer.id}
                                                            name={question.id}
                                                            value={answer.id}
                                                            onChange={() => handleAnswerChange(question.id, answer.id)}
                                                        />
                                                        <label
                                                            className={
                                                                state.showSuccess
                                                                    ? answer.isCorrect
                                                                        ? 'question__answer-correct'
                                                                        : 'question__answer-wrong'
                                                                    : ''
                                                            }
                                                            htmlFor={answer.id}
                                                        >
                                                            {answer.title}
                                                        </label>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        {state.showSuccess && (
                                            <div className="question__reasoning">
                                                <button className="question__reasoning-toggle" onClick={() => handleResoningToggle(question.id)}>
                                                    {state.reasonings[question.id] && (
                                                        <>
                                                            <span>Indoklás elrejtése</span>
                                                            <img src="/images/expand_less-micon.svg" alt="Indoklás elrejtése jelzés" />
                                                        </>
                                                    )}
                                                    {!state.reasonings[question.id] && (
                                                        <>
                                                            <span>Indoklás megtekintése</span>
                                                            <img src="/images/expand_more-micon.svg" alt="Indoklás megtekintése jelzés" />
                                                        </>
                                                    )}
                                                </button>
                                                <p
                                                    className="question__reasoning-text"
                                                    dangerouslySetInnerHTML={{
                                                        __html: state.reasonings[question.id] && question.reasoning,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ol>
                        <div className="page-kviz__leadform">
                            <p className="page-kviz__leadform-description">E-mail címed megadása fontos visszajelzés számunkra.</p>
                            <input placeholder="E-mail cím" className="contact-form__field" onChange={handleEmailChange} type="email" />
                            <div>
                                <input onChange={handleAcceptTermsChange} type="checkbox" id="accept-terms" name="terms" value="accept-terms" />
                                <label htmlFor="accept-terms">
                                    Tájékozódtam, hogy e-mail címem felelősségteljesen kezelik:{' '}
                                    <a target="_blank" className="page-kviz__link" href="/docs/digital-builds-adatkezelesi-nyilatkozat.pdf">
                                        Adatkezelési tájékoztató
                                    </a>
                                </label>
                            </div>
                            <button className="btn-primary page-kviz__leadform-button" onClick={handleSubmit} type="submit">
                                Kérem az eredményt!
                            </button>
                        </div>
                        {state.showSuccess && (
                            <div className="page-kviz__leadform-success">
                                <p>
                                    <b className="question__success-title">A te eredményed: {state.score}%.</b>
                                </p>
                                <p>Tesztelheted barátaidat is, ha megosztod a kvízt, vagy elküldöd nekik az alábbi linket:</p>
                                {state.isCopied && <p className="question__copy-success-title">Link kimásolva!</p>}
                                <div className="page-kviz__share-link-container" onClick={handleCopyClick}>
                                    <input className="page-kviz__share-link" type="text" ref={kvizLink} onChange={() => {}} value={`${pageUrl}${url}`} />
                                    <img className="question__copy-icon" src="/images/copy-micon.svg" alt="Másolás" />
                                </div>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: getSuccessMessage(resultTexts, state.score),
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </section>
                <section className="page-kviz__centered">
                    <div className="page-blog__centered-container">
                        <h3>DigitalBuilds</h3>
                        <p className="page-blog__paragraph">
                            A{' '}
                            <Link className="page-blog__link" to="/">
                                DigitalBuilds Kft.
                            </Link>{' '}
                            tanácsokkal és tapasztalattal támogatja új online ötleted megvalósítását. Foglalkozunk testreszabott, egyedi kivitelezésű{' '}
                            <strong>weboldalak</strong>, <strong>webalkalmazások</strong> fejlesztésével, <strong>webshopok</strong> vagy{' '}
                            <strong>mobil appok</strong> készítésével, illetve <strong>okos eszköz</strong>ökre szánt szoftverek írásával. Minden új alkalmazás,
                            amit fejlesztünk zökkenőmentesen skálázódik, néhány darab felhasználótól kezdve, a több 10-100 ezres felhasználó számig.
                            <br />
                            Lépj velünk{' '}
                            <a className="page-blog__link" href="#kapcsolat">
                                kapcsolat
                            </a>
                            ba és dolgozzunk együtt!
                        </p>
                    </div>
                </section>
                {additonalQuizes.length > 0 && (
                    <section className="page-kviz__additional-quizes">
                        <div className="page-blog__centered-container">
                            <h3>További kvízeink</h3>
                            <div className="row">
                                {additonalQuizes.map(
                                    ({
                                        url: additonalQuizUrl,
                                        metaTitle: additonalQuizTitle,
                                        metaDescription: additionalQuizDescription,
                                        image: additionalQuizImage,
                                    }) => {
                                        return (
                                            <QuizThumbnail
                                                key={`additional-quiz-${url}`}
                                                title={additonalQuizTitle}
                                                description={additionalQuizDescription}
                                                image={additionalQuizImage}
                                                url={additonalQuizUrl}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </section>
                )}
                <section className="page-kviz__additional-articles">
                    <div className="page-blog__centered-container">
                        <h3>Legújabb cikkeink</h3>
                        <div className="row">
                            {additonalArticles.map(
                                ({
                                    title: additionalArticleTitle,
                                    description: additionalArticleDescription,
                                    publishedTime: additionalArticlePublishedTime,
                                    publishedTimeString: additionalArticlePublishedTimeString,
                                    metaImageUrl: additionalArticleMetaImageUrl,
                                    url: additionalArticleUrl,
                                }) => {
                                    return (
                                        <ArticleThumbnail
                                            key={`article-${additionalArticleUrl}`}
                                            title={additionalArticleTitle}
                                            description={additionalArticleDescription}
                                            publishedTime={additionalArticlePublishedTime}
                                            publishedTimeString={additionalArticlePublishedTimeString}
                                            metaImageUrl={additionalArticleMetaImageUrl}
                                            url={additionalArticleUrl}
                                        />
                                    );
                                }
                            )}
                        </div>
                    </div>
                </section>
                <section className="page-main__contact" id="kapcsolat">
                    <div className="page-main__contact-container">
                        <h2>Kapcsolat</h2>
                        <p className="contact__description">
                            Van egy online vállalkozásötleted és fejlesztői segítséget keresel? Vedd fel velünk a kapcsolatot és dolgozzunk együtt!.
                        </p>
                        <ContactForm />
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};
