import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import articles from './articles.json';
import quizes from './quizes.json';

// import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Article from './components/Article/Article';
import QuizPage from './components/QuizPage/QuizPage';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Kviz from './pages/Kviz';

import EztMegCsinaldMegLegyszi from './pages/EztMegCsinaldMegLegyszi';
import LenneMegMitCsinalnod from './pages/LenneMegMitCsinalnod';
import MultiCegekEsABiztonsagIlluzioja from './pages/MultiCegekEsABiztonsagIlluzioja';
import IgyNezKiVelunkAMunka from './pages/IgyNezKiVelunkAMunka';
import NeEpitsCsillagRombolot from './pages/NeEpitsCsillagRombolot';
import WebappKeszitesSokIdo from './pages/WebappKeszitesSokIdo';
import LanguageContextProvider from './components/Language/LanguageContextProvider';

const articleLibrary = {
    EztMegCsinaldMegLegyszi,
    LenneMegMitCsinalnod,
    MultiCegekEsABiztonsagIlluzioja,
    IgyNezKiVelunkAMunka,
    NeEpitsCsillagRombolot,
    WebappKeszitesSokIdo,
};

export default () => {
    return (
        <LanguageContextProvider>
            <Router>
                {/* TODO: hashlinks on new pages should be respected <ScrollToTop />*/}
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/blog">
                        <Blog />
                    </Route>
                    <Route exact path="/kviz">
                        <Kviz />
                    </Route>
                    {articles.map(({ url, title, description, publishedTime, publishedTimeString, metaImageUrl, backgroundImageUrl, contentComponent }) => {
                        const ContentComponent = articleLibrary[contentComponent];
                        if (!ContentComponent) {
                            throw new Error(`Uknown article: ${contentComponent}`);
                        }
                        return (
                            <Route key={`route-${url}`} exact path={url}>
                                <Article
                                    title={title}
                                    description={description}
                                    url={url}
                                    metaImageUrl={metaImageUrl}
                                    backgroundImageUrl={backgroundImageUrl}
                                    publishedTime={publishedTime}
                                    publishedTimeString={publishedTimeString}
                                >
                                    <ContentComponent />
                                </Article>
                            </Route>
                        );
                    })}
                    {quizes.map(({ url, metaTitle, metaDescription, metaImage, title, description, image, questions, resultTexts }) => {
                        return (
                            <Route key={`route-${url}`} exact path={url}>
                                <QuizPage
                                    url={url}
                                    metaTitle={metaTitle}
                                    metaDescription={metaDescription}
                                    metaImage={metaImage}
                                    title={title}
                                    description={description}
                                    image={image}
                                    questions={questions}
                                    resultTexts={resultTexts}
                                />
                            </Route>
                        );
                    })}
                </Switch>
            </Router>
        </LanguageContextProvider>
    );
};
