import React from 'react';

import { Link } from 'react-router-dom';

export default () => {
    return (
        <>
            <section className="page-blog__text">
                <div className="page-blog__text-container page-blog__text-container--columns">
                    <p className="page-blog__paragraph page-blog__intro">
                        Ebben a rövid bejegyzésben szeretnénk összefoglalni azokat a lépéseket, amiken minden ügyfelünkkel végigmegyünk. Így egy teljesebb képet
                        biztosítva neked arról, hogyan lesz ötletedből működő, piacon kipróbálható termék.
                    </p>
                    <p className="page-blog__paragraph">Röviden összefoglalva a lépések:</p>
                    <ol>
                        <li>Ötlet</li>
                        <li>Megkeresés, időpontfoglalás</li>
                        <li>Első megbeszélések</li>
                        <li>Konzultációk</li>
                        <li>Szerződés egyeztetés</li>
                        <li>Aláírás és előlegfizetés</li>
                        <li>Iteratív munka, folyamatos visszajelzésekkel</li>
                        <li>Fizetés, termékátadás</li>
                        <li>Jó visszajelzések esetén hosszútávú közös munka</li>
                    </ol>
                    <p className="page-blog__paragraph">
                        Nulladik lépés: Legyen egy jó ötleted, legyen ez app, webapp, webshop, blog, bármilyen online rendszer.
                    </p>
                    <p className="page-blog__paragraph">
                        Első lépés, hogy elhatározd magad: Higgy az ötletedben és komolyan ki akard próbálni, hogy működik-e.
                    </p>
                    <p className="page-blog__paragraph">
                        Ha ez már megvan írj nekünk, vagy hívj fel. Beszéljünk meg egy időpontot, ahol megismerjük egymást és átbeszéljük elképzeléseidet. (
                        <a className="page-blog__link" href="mailto:info@digitalbuilds.hu">
                            info@digitalbuilds.hu
                        </a>{' '}
                        /{' '}
                        <a className="page-blog__link" href="tel:+36304108878">
                            +36 30 410 8878
                        </a>
                        ) Ez díjtalan, semmilyen kötelezettséggel nem jár és körülbelül 1-2 órát vesz igénybe. Amennyiben ötleted szenzitív jellegű, a
                        megbeszélés előtt titoktartási nyilatkozatot írunk alá.
                    </p>
                    <p className="page-blog__paragraph">
                        Az első pár megbeszélés után, már tudjuk nagy vonalakban az elképzeléseidet, így kezdetét veheti a konzultációs időszak, ahol pontosan
                        elmondjuk milyen részletekre van szükségünk tőled a fejlesztés elkezdéséhez. Egyúttal ezalatt az idő alatt, segítünk kidolgozni ötleted
                        hiányosságait, már amennyiben vannak. Ezt olykor nyers őszinteséggel tesszük, mivel úgy gondoljuk, csak az egyértelmű kommunikáció
                        vezethet hosszútávú és gyümölcsöző együttműködéshez. Ekkor tisztázzuk pontosan azt is, milyen funkciók kerülnek majd a leszállított
                        alkalmazásba. Ha még nem tetted, kérlek ezen a ponton olvasd el a következő rövid bejegyzést:{' '}
                        <Link className="page-blog__link" to="/blog/ne-epits-csillag-rombolot">
                            Ne építs csillagrombolót!
                        </Link>
                    </p>
                    <p className="page-blog__paragraph">
                        Amint minden a rendelkezésünkre áll, elkészítjük a szerződésmintát, amit megbeszélések és esetleges módosítások után mindketten
                        aláírunk.
                    </p>
                    <p className="page-blog__paragraph">Amire a szerződésben számíthatsz:</p>
                    <ul>
                        <li>A projekt végén egy előre megbeszélt, szerződésben rögzített funkciókkal rendelkező alkalmazást kapsz kézhez.</li>
                        <li>A közösen véglegesített szerződésben szereplő ajánlatra 14 napod lesz reagálni, ezután az ajánlat elévül.</li>
                        <li>A projekt átfutási ideje a fejlesztés kezdetétől számítva várhatóan 1-3 hónap. (Miért ennyi? Olvasd el a következő bejegyzést: <Link className="page-blog__link" to="/blog/webapp-keszites-sok-ido">Webapp készítés: Sok idő?</Link>)</li>
                        <li>
                            Az első pár megbeszélés kivételével a konzultációkat óradíjas alapon kiszámlázzuk. Természetesen a konzultációk kezdete előtt már
                            tudni fogod mi számít annak.
                        </li>
                        <li>
                            A várható teljes költség 1 - 6 millió forint, komplexitástól, kivitelezési időtől és a piacon tipikus fejlesztői óradíjtól függően.
                        </li>
                        <li>A munka megkezdése előtt 10-30% előleget kell fizetned.</li>
                        <li>Az árajánlat tartalmaz 1 éves fenntartási költséget és garanciát a felmerülő hibák ingyenes javítására.</li>
                    </ul>
                    <p className="page-blog__paragraph">
                        Amint megtörtént az aláírás és előleg fizetés, mi azonnal elkezdjük a munkát, amibe folyamatos betekintést biztosítunk. Ez általad
                        preferált, vagy általunk javasolt online felületen, illetve személyes találkozók során történik. Ez alatt az időszak alatt tisztázni
                        tudunk bármilyen kérdést, ami felmerül a készülő alkalmazás használatával kapcsolatban.
                    </p>
                    <p className="page-blog__paragraph">
                        A megbeszélt határidőre a fejlesztést befejezzük és a terméket átadjuk. Ez egyúttal az előlegen kívül fennmaradó fejlesztési költség
                        kifizetésének határideje is. Az elkészült termék már egy működő weboldal / webapp / mobilapp / webshop lesz, amit hirdethetsz /
                        felhasználókat gyűjthetsz rá, vagy munkahelyi rendszer esetén bevezetheted a vállalkozásodban.
                    </p>
                    <p className="page-blog__paragraph">
                        Kis idő elteltével, ha elégedett vagy és bizalmat szavazol nekünk a további munkára, szívesen dolgozunk veled és bővítjük tovább
                        alkalmazásodat vagy akár új ötlet, új prototípus fejlesztésébe kezdünk.
                    </p>
                </div>
            </section>
        </>
    );
};
