import React from 'react';

import { Link } from 'react-router-dom';

export default () => {
    return (
        <>
            <section className="page-blog__text">
                <div className="page-blog__text-container page-blog__text-container--columns">
                    <p className="page-blog__paragraph page-blog__intro">
                        Előző bejegyzésünkben felvázoltuk pár rövid lépésben, hogyan tudjuk megvalósítani webapp vagy mobilapp ötletedet, azaz, hogy néz ki
                        velünk a közös munka. (
                        <Link className="page-blog__link" to="/blog/hogy-dolgozhatunk-egyutt">
                            Hogy dolgozhatunk együtt?
                        </Link>
                        ) Most több cikken át arról szeretnénk írni, miért pont így dolgozunk. Az első ilyen cikk az általad kitalált weboldal, web vagy mobilalkalmazás funkcióiról fog szólni. Azaz mire figyelj, ha megbízol minket a fejlesztéssel.
                    </p>
                    <p className="page-blog__paragraph">
                        Kezdjük is az elejéről. Az egész, ha jól sejtem, úgy indult, hogy beugrott egy gondolat és egyből érezted: na, ez egy igazán jó ötlet.
                        Aztán jönnek a pozitív megerősítések: Látod a piaci rést, látod, hogy meg van a szaktudás, tudod kihez kell fordulnod, hogy fogod
                        hirdetni, még a költségvetésbe is belefér, stb...
                    </p>
                    <p className="page-blog__paragraph">
                        És igen, csak megerősíteni tudlak, ha ez mind meg van, jó úton jársz és lehet fantázia abban, amit kitaláltál. Az ördög viszont a
                        részletekben rejlik, mégpedig: Hogyan jutsz el a megvalósításig, sőt, hogyan jutsz el a sikerig?
                    </p>
                    <p className="page-blog__paragraph">
                        A sikerhez vezető út, egyik kulcs pillére nem más, mint az emberek, akik az alkalmazásod használják vagy a weboldalad látogatják. A fő
                        kérdés: Lesznek egyáltalán ilyen emberek? Lesz aki tényleg használja, amit kitaláltál? Ha igazán hiszel az ötletedben, te abban is
                        biztos vagy, hogy igen a válasz. A valóság viszont gyakran mond mást. A bölcs stratégia: erre már az elején felkészülni, méghozzá
                        meglepően egyszerű módon. Adj választ a kérdésre minél hamarabb, minél hamarabb derüljön ki, hogy használják-e a termékedet.
                    </p>
                    <p className="page-blog__paragraph">
                        Ez a gondolat vezérelt bennünket abban, hogy mindenkit, aki új app vagy webes ötlettel keres meg minket, rátereljünk a minél hamarabbi
                        piaci visszajelzés begyűjtésére. Ezt pedig a leggyorsabban egy alkalmazás prototípus, más kifejezéssel élve egy MVP (Minimum Viable
                        Product) legyártásával lehet elérni. Valójában, itt arról van szó, hogy a sok funkció és felhasználási mód közül, amit kitaláltál,
                        válaszd ki azt a néhányat, ami kulcsfontosságú a termék használhatóságához. Válaszold meg: Melyek azok a funkciók, amik
                        elengedhetetlenek ahhoz, hogy a dolog működjön? Ez nem azt jelenti, hogy örökre meg kell válnod a további funkcióötletektől és azok
                        soha nem kerülnek az app-ba vagy weboldalba. Itt egy időrendi sorrend felállításáról van szó, illetve priorizálásról. A termék fokozatos
                        bővítéséről, aminek egy fázisában a bővítéseket valós piaci visszajelzések alapján teheted meg, hiszen az MVP-t már használják majd az
                        emberek.
                    </p>
                    <p className="page-blog__paragraph">
                        Minden design és funkció az MVP után valós problémát tud majd így megoldani, a te tényleges célközönségedre optimalizálva, annak
                        visszajelzéseire építve. Úgy gondoljuk, ez a gondolkodásmód a sikerhez vezető út egyik alapköve.
                    </p>
                    <p className="page-blog__paragraph page-blog__intro">Összefoglalva: Priorizáld az appodba kerülő funkciókat okosan, ne építs egyből csillagrombolót.</p>
                    <p className="page-blog__paragraph">
                        Egyet értesz? Másképp gondolod? Írj nekünk és meghallgatjuk véleményedet:{' '}
                        <a className="page-blog__link" href="mailto:info@digitalbuilds.hu">
                            info@digitalbuilds.hu
                        </a>{' '}
                        /{' '}
                        <a className="page-blog__link" href="https://www.facebook.com/digital.builds" target="_blank">
                            Facebook
                        </a>{' '}
                        /{' '}
                        <a className="page-blog__link" href="tel:+36304108878">
                            +36 30 410 8878
                        </a>
                    </p>
                    <p className="page-blog__paragraph">
                        Szívesen ránk bíznád ötleted technikai megvalósítását? Keress minket bizalommal, vedd fel velünk a <a className="page-blog__link" href="#kapcsolat">kapcsolat</a>ot!
                    </p>
                    <p className="page-blog__paragraph">
                        Ha az alkalmazásra épülő vállalkozások további intrikái érdekelnek, következő bejegyzésünkben egy tipikus web/mobil app prototípus
                        elkészítési idejéről olvashatsz:{' '}
                        <Link className="page-blog__link" to="/blog/webapp-keszites-sok-ido">
                            Webapp készítés: Sok idő?
                        </Link>
                    </p>
                </div>
            </section>
        </>
    );
};
