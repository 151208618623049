import React, { useState, useEffect } from 'react';
import LanguageContext from './LanguageContext';

const langs = ['hu', 'en'];
const langKey = 'LANG';

const getDomainLang = () => window.location.href.toLowerCase().includes('digitalbuilds.co') ? 'en' : 'hu';

const getSavedLang = () => {
    try {
        const lang = window.localStorage.getItem(langKey);
        return lang && langs.indexOf(lang) > -1 ? lang : null;
    } catch {
        return null;
    }
};

const saveLang = lang => {
    try {
        window.localStorage.setItem(langKey, lang);
    } catch {}
};

const getInitialLang = () => getSavedLang() || /* getBrowserLang() || */ getDomainLang();

const LanguageContextProvider = ({ children }) => {
    const [context, setContext] = useState({
        currentLanguage: getInitialLang(),
        changeLanguage(lang) {
            saveLang(lang);
            setContext(ctx => ({ ...ctx, currentLanguage: lang }));
        },
    });

    useEffect(() => {
        const lang = context.currentLanguage === 'en' ? 'en_US' : 'hu_HU';
        // window.loadFbChat(lang);
    });

    return <LanguageContext.Provider value={context}>{children}</LanguageContext.Provider>;
};

export default LanguageContextProvider;
