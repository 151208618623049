import React from 'react';

import articles from '../articles.json';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import MetaSite from '../components/MetaSite/MetaSite';
import ContactForm from '../components/ContactForm/ContactForm';
import ArticleThumbnail from '../components/ArticleThumbnail/ArticleThumbnail';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';

export default () => {
    return (
        <>
            <ScrollToTop />
            <MetaSite
                title="Blog – DigitalBuilds"
                description="Egyedi mobil és webes applikációk tervezése és elkészítése ötlettől a kész termékig. Kezdő vállalkozóknak, startupoknak, vagy már működő cégeknek."
                url="https://digitalbuilds.hu/blog"
                imageUrl="https://digitalbuilds.hu/images/digitalbuilds_1200x630.jpg"
            />
            <Header></Header>
            <main className="page-main">
                <section className="page-blog__additional-articles">
                    <div className="page-blog__centered-container">
                        <h1>DigitalBuilds Blog</h1>
                        <div className="row">
                            {articles.map(({ title, description, publishedTime, publishedTimeString, metaImageUrl, url }) => {
                                return (
                                    <ArticleThumbnail
                                        key={`additional-article-${url}`}
                                        title={title}
                                        description={description}
                                        publishedTime={publishedTime}
                                        publishedTimeString={publishedTimeString}
                                        metaImageUrl={metaImageUrl}
                                        url={url}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </section>
                <section className="page-blog__contact" id="kapcsolat">
                    <div className="page-blog__contact-container">
                        <p className="contact__description">Ha szeretnél kapcsolatba lépni velünk, küldj nekünk üzenetet az alábbiak megadásával:</p>
                        <ContactForm />
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};
